@import 'two-app-ui/build/esm/src/scss/theme/_variables';

.job-document-reference{
  &.stage-draft{
    .p-chip{
      background-color: #bcbec0;
      color: #000;
    }
  }
  &.stage-in-progress{
    .p-chip{
    background-color: #ff9f42;
    color: #000;
    }
  }
  &.stage-released{
    .p-chip{
    background-color: #5486ea;
    color: #fff;
    }
  }
  &.stage-approved, &.stage-done{
    .p-chip{
    background-color: #29c76f;
    color: #fff;
    }
  }
  &.stage-cancelled{
    .p-chip{
    background-color: #000;
    color: #fff;
    }
  }
}